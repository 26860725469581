/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react'
import PropTypes from 'prop-types'
import {Helmet} from 'react-helmet'
import {useStaticQuery, graphql} from 'gatsby'

function Seo(props) {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					description
					title
					lang
				}
			}
		}
	`)

	// ページタイトルが指定されている場合は、「ページごとのタイトル|サイト名」で表示させる
	const title = props.pagetitle
		? `${props.pagetitle}|${data.site.siteMetadata.title}`
		: data.site.siteMetadata.title
	const description = props.pageDesc || data.site.siteMetadata.description

	return (
		<Helmet>
			<html lang={data.site.siteMetadata.lang} />
			<title>{title}</title>
			<meta name='description' content={description} />
			{process.env.GATSBY_ACTIVE_ENV === 'dev' && (
				<meta name='robots' content='noindex' />
			)}
			{process.env.GATSBY_ACTIVE_ENV === 'prod' && (
				<meta name='robots' content='index' />
			)}
		</Helmet>
	)
}

export default Seo
