import * as React from 'react'
import {Link} from 'gatsby'
import {push as Menu} from 'react-burger-menu'

const Header = () => (
	<>
		<Menu>
			<Link to='/' className='menu-item'>
				HOME
			</Link>
			<Link to='/contact' className='menu-item'>
				تواصل معنا (نقبل اللغة الإنجليزية فقط)
			</Link>
			<a href='https://aikiishinryu.jp/' className='menu-item'>
				日本語ページ
			</a>
			<a href='https://en.aikiishinryu.jp/' className='menu-item'>
				English page
			</a>
		</Menu>
		{/* <header className='global-header container'>
			<p>Aiki ishinryu</p>
		</header> */}
	</>
)

export default Header
