import * as React from 'react'

import Header from './header'
import Footer from './footer'
import '../styles/layout.scss'


const Layout = ({children}) => {
	return (
		<>
			<Header />
			{children}
			<Footer />
		</>
	)
}

export default Layout
