import React from 'react'
import {Link} from 'gatsby'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
	faFacebookSquare,
	faInstagram,
	faYoutube,
} from '@fortawesome/free-brands-svg-icons'

const Footer = () => {
	return (
		<footer className='global-footer'>
			{/* <Link to={`/`}>
				<h2>相氣一進流</h2>
			</Link> */}
			<section className='footer-main'>
				<section className='footer-section'>
					<div className='footerNav'>
						<ul>
							<li>
								<Link to={`/`}>HOME</Link>
							</li>
							<li>
								<Link to={`/contact`}>CONTACT</Link>
							</li>
							<li>
								<a
									href='https://en.aikiishinryu.jp/privacy'
									target='_blank'
									rel='noopener noreferrer'>
									PRIVACY POLICY
								</a>
							</li>
							<li>
								<a
									href={`https://en.aikiishinryu.jp/`}
									target='_blank'
									rel='noopener noreferrer'>
									English page
								</a>
							</li>
							<li>
								<a
									href={`https://aikiishinryu.jp/`}
									target='_blank'
									rel='noopener noreferrer'>
									Japanese page
								</a>
							</li>
						</ul>
						<ul className='sns'>
							<li>
								<a href='https://www.facebook.com/aikijutu/'>
									<FontAwesomeIcon
										icon={faFacebookSquare}
										style={{color: '#fff'}}
									/>
								</a>
							</li>
							<li>
								<a href='https://www.instagram.com/aiki_ishinryu_jiujitsu/'>
									<FontAwesomeIcon icon={faInstagram} style={{color: '#fff'}} />
								</a>
							</li>
							<li>
								<a href='https://www.youtube.com/channel/UC5L5ro8sDa7048WRFhJ4qTQ'>
									<FontAwesomeIcon icon={faYoutube} style={{color: '#fff'}} />
								</a>
							</li>
						</ul>
					</div>
				</section>
			</section>
			<div className='site-detail'>
				<p>Copyright © 相氣一進流 All Rights Reserved.</p>
				<p>
					Built by{' '}
					<a
						href='https://yuyak.netlify.app/'
						target='_blank'
						rel='noopener noreferrer'>
						yuyak
					</a>
				</p>
			</div>
		</footer>
	)
}

export default Footer
